import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
    {/* Add GlobalSign SSL Seal Script */}
    <span id="ss_zcom_img_wrapper_115-57_image_en">
      <a href="https://jp.globalsign.com/" target="_blank" rel="nofollow">
        <img
          alt="CLICK TO VERIFY: This site uses a GlobalSign SSL Certificate to secure your personal information."
          border="0"
          id="ss_img"
          src="//seal.globalsign.com/SiteSeal/images/gs_noscript_115-55_en.gif"
        />
      </a>
    </span>
  </React.StrictMode>
);

// Dynamically load the GlobalSign script
const script = document.createElement('script');
script.type = 'text/javascript';
script.src = '//seal.globalsign.com/SiteSeal/zcom/zcom_image_115-57.js';
script.defer = true;
document.body.appendChild(script);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
import React, { useState, useEffect } from 'react';
import axios from 'axios';

function App() {
  //axios.defaults.baseURL="https://localhost:7208/api";
  axios.defaults.baseURL="https://krsparepart.com:5001/api";
  const [products, setProducts] = useState([]);
  const [error, setError] = useState(null);

  const [selectfiles, setselectfiles] =useState([]);
  const [uploadfiles, setuploadfiles] = useState([]);

    

  const uploadfile = () => {
  const formData = new FormData();
  
      
    Array.from(selectfiles).forEach(file => {
      formData.append('files',file);
    });
  

    axios.post('/FileUpload/upload_multiple',formData,{ 
      headers:{
        'Content-Type' : 'multipart/form-data'
      }
    })

    .then(response => {
      alert('File upload successfully!');
      fetchFiles();
    })
    .catch(error=> {
      console.error('Error Uploading file:',error);
    });
  };

  const fetchFiles = () => {
    axios.get('/fileupload/files')
    
    .then(response => {
      setuploadfiles(response.data);
    })
  .catch(error => {
  console.error('Error fetching Files:', error);
  });
};


useEffect(()=> {
  fetchFiles();
},[]);




  useEffect(() => {
    const fetchData = async () => {
      try {

        const response = await axios.get('/GetProducts');
        setProducts(response.data.data);
      } catch (err) {
        console.error('There was an error fetching the products!', err);
        setError(err.message);
      }
    };

    fetchData();
  }, []);

  return (
    <div>
      <h1>Product List</h1>
      {error && <div style={{ color: 'red' }}>Error: {error}</div>}
      <ul>
        {products.map((product) => (
          <li key={product.id}>
            {product.name}: ${product.price}
          </li>
        ))}
      </ul>

      {/* Embed GlobalSign Seal */}
      <div id="ss_zcom_img_wrapper_115-57_image_en">
        <a href="https://jp.globalsign.com/" target="_blank" rel="nofollow">
          <img
            alt="CLICK TO VERIFY: This site uses a GlobalSign SSL Certificate to secure your personal information."
            border="0"
            id="ss_img"
            src="//seal.globalsign.com/SiteSeal/images/gs_noscript_115-55_en.gif"
          />
        </a>
      </div>



      <div>
        <h>Upload file</h>

        <input type="file" multiple onChange={e => setselectfiles(e.target.files)} />
        
        <button onClick={uploadfile}>Upload</button>

        <h2>Upload Files</h2>
      <ul>
      {uploadfiles.map(file => (//  {uploadfiles.map(file => (
          <li key={file}>
            <img 
           //src={`https://localhost:7208/files/${file}`}   //หลังบ้าน
           src={`https://krsparepart.com:5001/files/${file}`}   //หลังบ้าน
           alt={file}
            style={{width: '100px',height:'100px'}}
           />
          </li>
        ))

        }
      </ul>

      </div>

    </div>



  );
}

export default App;
